<template>
  <div class="skeleton-profile-referral">
    <div class="skeleton-profile-referral__desktop">
      <div class="skeleton-profile-referral__layout">
        <Skeletor class="skeleton-profile-referral__info" as="div" />
        <Skeletor class="skeleton-profile-referral__promo" as="div" />
      </div>
    </div>

    <div class="skeleton-profile-referral__mobile">
      <Skeletor class="skeleton-profile-referral__info" as="div" />

      <div class="skeleton-profile-referral__theme">
        <Skeletor class="skeleton-profile-referral__theme-item" as="div" />
        <Skeletor class="skeleton-profile-referral__theme-item" as="div" />
      </div>
    </div>

    <Skeletor class="skeleton-profile-referral__tabs" as="div" />

    <Skeletor class="skeleton-profile-referral__summary-info" as="div" />

    <div class="skeleton-profile-referral__levels">
      <Skeletor class="skeleton-profile-referral__levels__title" as="div" />
      <Skeletor class="skeleton-profile-referral__levels__text" as="div" />

      <div class="skeleton-profile-referral__levels__items">
        <Skeletor
          v-for="n in LEVELS_COUNT"
          :key="n"
          class="skeleton-profile-referral__levels__item"
          as="div" />
      </div>
    </div>

    <div class="skeleton-profile-referral__sort">
      <div class="skeleton-profile-referral__sort__head">
        <Skeletor class="skeleton-profile-referral__sort__title" as="div" />
        <Skeletor class="skeleton-profile-referral__sort__dropdown" as="div" />
      </div>

      <Skeletor class="skeleton-profile-referral__sort__tabs" as="div" />

      <div class="skeleton-profile-referral__sort__table">
        <Skeletor
          v-for="n in TABLE_ROWS_COUNT"
          :key="n"
          class="skeleton-profile-referral__sort__row"
          as="div" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const LEVELS_COUNT = 5;
  const TABLE_ROWS_COUNT = 10;

</script>

<style src="~/assets/styles/components/skeleton-preloader/profile-referral-page.scss" lang="scss" />
